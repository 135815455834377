// eslint-disable-next-line no-undef
initializeHotJar = function () {
  window.hj =
    window.hj ||
    function () {
      (window.hj.q = window.hj.q || []).push(arguments);
    };
  window._hjSettings = { hjid: 5274, hjsv: 6 };
  var a = document.getElementsByTagName('head')[0];
  var r = document.createElement('script');
  r.async = true;
  r.src = 'https://static.hotjar.com/c/hotjar-' + window._hjSettings.hjid + '.js?sv=' + window._hjSettings.hjsv;
  a.appendChild(r);
};
